<style>

</style>
<script>
import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';
import { get_acsrf_token } from "@/methods";
import eventHandler from "@/state/events";

export default {
  props: ['team_id', 'options'],
  components: {
    VclCode,
    VclList,
    VclBulletList
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.team = data.team;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    async joinTeam() {
        const acsrf_token = await get_acsrf_token();
        const payload = {
            acsrf_token: acsrf_token
        };
        const response = await fetch(process.env.VUE_APP_ROOT_API + `v1/teams/${this.team_id}/join`, {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
        });
        if (!response.ok) {
            return this.$swal({
                icon: 'error',
                text: this.$t('error.server.generic.message')
            });
        }
        this.options.permissions.join = false;
        this.team.metrics.memberships += 1;
        this.$store.commit('addTeam', { memberships: this.team.metrics.memberships, ...this.team});
        eventHandler.$emit('reload-navigation')
    }
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      ready: false,
      error: false,
      team: null
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(team.metrics.memberships) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('teams.view.overview.members') }}
                      </p>
                    </div>
                    <div class="col-lg-2 text-right">
                      <h3>
                        {{ $n(team.metrics.resources) }}
                      </h3>
                      <p class="text-muted mb-0">
                        {{ $t('teams.view.overview.resources') }}
                      </p>
                    </div>
                      <div class="ml-auto mr-3">
                          <button v-if="options.permissions.join" class="btn btn-primary btn-lg text-uppercase" @click="joinTeam()">
                              <h5 class="mb-0">
                                  {{ $t('teams.view.overview.join.button') }}
                              </h5>
                          </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <h4>
                        {{ $t('teams.view.overview.resources') }}
                      </h4>
                      <div class="table-responsive mb-0" v-if="team.resources.length">
                        <table class="table table-centered table-nowrap">
                          <thead class="thead-light">
                          <tr>
                            <th>{{ $t('teams.view.overview.resource_list.columns.id') }}</th>
                            <th>{{ $t('teams.view.overview.resource_list.columns.type') }}</th>
                            <th>{{ $t('teams.view.overview.resource_list.columns.identifier') }}</th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="resource in team.resources" :key="resource.id">
                            <td>
                              <b class="text-body font-weight-bold">{{resource.id}}</b>
                            </td>
                            <td>
                              <template v-if="resource.type === 'server'">
                                <b class="text-uppercase">Server</b>
                              </template>
                              <template v-else-if="resource.type === 'banlist'">
                                <b class="text-uppercase">Ban List</b>
                              </template>
                              <template v-else>
                                <i>Other</i>
                              </template>
                            </td>
                            <td>{{resource.identifier}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <div class="text-center">
                          <h2 class="text-center text-muted text-uppercase p-0 mt-4 mb-4">
                            {{ $t('teams.view.overview.no_resources') }}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
